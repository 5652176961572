import instance from "./Request";

export const getUsers = async () => {
  const request = await instance();
  let data = await request.get("/partner-users").catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const newUser = async (user) => {
  const request = await instance();
  let data = await request
    .post("/partner-users/create", user)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const changePassword = async (user, password) => {
  const request = await instance();
  let data = await request
    .post("/partner-users/update-password", {
      id_user: user,
      password: password,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const changePasswordDriver = async (user, password) => {
  const request = await instance();
  let data = await request
    .post("/drivers/update-password", {
      id_user: user,
      password: password,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const editUser = async (user) => {
  const request = await instance();
  let data = await request
    .post("/partner-users/update", user)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const deleteUser = async (id) => {
  const request = await instance();
  let data = await request
    .post("/partner-users/delete", { id })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
