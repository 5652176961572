import React from "react";
import styles from "../styles/DriverSchedules.module.css";
import Close from "../../../components/Close";
import { Button, Col, DatePicker, TimePicker, Form, Row } from "antd";
import moment from "moment";
const DriverSchedules = (props) => {
  const { closeDriverSchedules, horario, setHorario } = props;
  const format = "HH:mm";

  const handleTimeChange = (day, value) => {
    setHorario((prev) => ({
      ...prev,
      [day]: value ? [value[0].format(format),value[1].format(format)] : null,
    }));
  };

  return (
    <div>
      <div className={styles.header}>
        <span className={styles.title}>HORARIO LABORAL</span>
        <Close action={closeDriverSchedules} />
      </div>
        <Row>
          <Col span={12}>
              <span className={styles.labelFormItem}>LUNES</span>
              <TimePicker.RangePicker 
                  format={format} 
                  className={styles.timePicker}
                  placeholder={["Inicio", "Finaliza"]}
                  value={horario['monday'] ? [moment(horario['monday'][0], format),moment(horario['monday'][1], format)] : null} 
                  onChange={(value) => handleTimeChange('monday', value)} />            
          </Col>
          <Col span={12}>
            <span className={styles.labelFormItem}>MARTES</span>
            <TimePicker.RangePicker 
                format={format} 
                className={styles.timePicker} 
                placeholder={["Inicio", "Finaliza"]}
                value={horario['tuesday'] ? [moment(horario['tuesday'][0], format),moment(horario['tuesday'][1], format)] : null} 
                onChange={(value) => handleTimeChange('tuesday', value)} />
          </Col>
          <Col span={12}>
            <span className={styles.labelFormItem}>MIÉRCOLES</span>
            <TimePicker.RangePicker 
                  format={format} 
                  className={styles.timePicker} 
                  placeholder={["Inicio", "Finaliza"]}
                  value={horario['wednesday'] ? [moment(horario['wednesday'][0], format),moment(horario['wednesday'][1], format)] : null} 
                  onChange={(value) => handleTimeChange('wednesday', value)} />
          
          </Col>
          <Col span={12}>
            <span className={styles.labelFormItem}>JUEVES</span>
            <TimePicker.RangePicker 
                format={format} 
                className={styles.timePicker} 
                placeholder={["Inicio", "Finaliza"]}
                value={horario['thursday'] ? [moment(horario['thursday'][0], format),moment(horario['thursday'][1], format)] : null} 
                onChange={(value) => handleTimeChange('thursday', value)} />
          
          </Col>
          <Col span={12}>
            <span className={styles.labelFormItem}>VIERNES</span>
            <TimePicker.RangePicker 
                  format={format} 
                  className={styles.timePicker} 
                  placeholder={["Inicio", "Finaliza"]}
                  value={horario['friday'] ? [moment(horario['friday'][0], format),moment(horario['friday'][1], format)] : null} 
                  onChange={(value) => handleTimeChange('friday', value)} />
          </Col>
          <Col span={12}>
            <span className={styles.labelFormItem}>SÁBADO</span>
            <TimePicker.RangePicker 
                  format={format} 
                  className={styles.timePicker}
                  placeholder={["Inicio", "Finaliza"]} 
                  value={horario['saturday'] ? [moment(horario['saturday'][0], format),moment(horario['saturday'][1], format)] : null} 
                  onChange={(value) => handleTimeChange('saturday', value)} />
          </Col>
          <Col span={12}>
            <span className={styles.labelFormItem}>DOMINGO</span>
            <TimePicker.RangePicker 
                format={format} 
                className={styles.timePicker} 
                placeholder={["Inicio", "Finaliza"]}
                value={horario['sunday'] ? [moment(horario['sunday'][0], format),moment(horario['sunday'][1], format)] : null} 
                onChange={(value) => handleTimeChange('sunday', value)} />
          </Col>
        </Row>
    </div>
  );
};

export default DriverSchedules;
