import { createSlice } from "@reduxjs/toolkit";
import {
  getVehicles,
  newVehicle,
  editVehicle,
  deleteVehicle,
  createDriver,
  updateDriver,
  availableDriver,
  getDriverInfo,
  listPausaDrivers,
  approvePauseDriver,
  deniedPauseDriver,
  getPausesHistory,
} from "../../api/Vehicles";

export const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState: {
    vehicles: [],
    driverInfo: { id: null },
    listPauses: [],
    pausesHistory:[]
  },
  reducers: {
    setVehicles: (state, action) => {
      state.vehicles = action.payload;
    },
    setVehicle: (state, action) => {
      const index = state.vehicles.findIndex(
        (el) => el.id === action.payload.id
      );
      if (index > -1) {
        state.vehicles[index] = action.payload;
      }
    },
    addVehicle: (state, action) => {
      state.vehicles.push(action.payload);
    },
    removeVehicle: (state, action) => {
      const index = state.vehicles.findIndex((el) => el.id === action.payload);
      if (index > -1) {
        state.vehicles.splice(index, 1);
      }
    },
    setDriverInfo: (state, action) => {
      state.driverInfo = action.payload;
    },
    setListPauses: (state, action) => {
      state.listPauses = action.payload;
    },
    setPausesHistory:(state,action) => {
      state.pausesHistory = action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setVehicles,
  setVehicle,
  addVehicle,
  removeVehicle,
  setDriverInfo,
  setListPauses,
  setPausesHistory
} = vehiclesSlice.actions;

export const getAllVehicles = () => async (dispatch) => {
  try {
    const response = await getVehicles();
    if (!response.error && response.status === 200) {
      dispatch(setVehicles(response.data));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const createVehicle = (data) => async (dispatch) => {
  try {
    const response = await newVehicle(data);
    if (!response.error && response.status === 200) {
      dispatch(addVehicle(response.data));
      return {
        status: "success",
        vehicle: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const createDriverThunk = (data) => async (dispatch) => {
  try {
    const response = await createDriver(data);
    if (!response.error && response.status === 200) {
      dispatch(addVehicle(response.data));
      return {
        status: "success",
        driver: response.data,
      };
    }
    return {
      status: "error",
      type: "unknown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const updateDriverThunk = (data) => async (dispatch) => {
  try {
    const response = await updateDriver(data);
    if (!response.driver && response.status === 200) {
      dispatch(setVehicle(response.data));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unknown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const availableDriverThunk = (data) => async (dispatch) => {
  try {
    const response = await availableDriver(data);
    if (!response.error && response.status === 200) {
      dispatch(setDriverInfo(response.data.driver));
      return {
        status: "success",
        driver: response.data,
      };
    }
    return {
      status: "error",
      type: "unknown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const updateVehicle = (data) => async (dispatch) => {
  try {
    const response = await editVehicle(data);
    if (!response.error && response.status === 200) {
      dispatch(setVehicle(response.data));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const removeMonitorVehicle = (id) => async (dispatch) => {
  try {
    const response = await deleteVehicle(id);
    if (!response.error && response.status === 200) {
      dispatch(removeVehicle(id));
      return {
        status: "success",
        // departments: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const getDriverInfoThunk = (driverId) => async (dispatch) => {
  try {
    const response = await getDriverInfo(driverId);
    if (response.status === 200) {
      dispatch(setDriverInfo(response.data.driver));
      return {
        status: "success",
        data: response.data,
      };
    }
    return {
      status: "error",
      type: "unknown",
    };
  } catch (error) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const listPausesDriversThunk = (data) => async (dispatch) => {
  // data = { "userId": "10" }
  try {
    const response = await listPausaDrivers(data);
    if (response.status === 200) {
      dispatch(setListPauses(response.data.listPauses));
      return {
        status: "success",
        data: response.data,
      };
    }
    return {
      status: "error",
      type: "unknown",
    };
  } catch (error) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const approvePauseThunk = (data) => async (dispatch) => {
  // data = { "driver_id": "145", "pause_id":"1" }
  try {
    const response = await approvePauseDriver(data);
    if (!response.error && response.status === 200) {
      dispatch(setListPauses(response.data.listPauses));
      return {
        status: "success",
        driver: response.data,
      };
    }
    return {
      status: "error",
      type: "unknown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const deniedPauseThunk = (data) => async (dispatch) => {
  // data = { "driver_id": "145", "pause_id":"1", "comment":"razon no valida para aceptarla" }
  try {
    const response = await deniedPauseDriver(data);
    if (!response.error && response.status === 200) {
      dispatch(setListPauses(response.data.listPauses));
      return {
        status: "success",
        driver: response.data,
      };
    }
    return {
      status: "error",
      type: "unknown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const getPausesHistoryThunk = (data) => async (dispatch) => {
  try {
    const response = await getPausesHistory(data);
    if (!response.error && response.status === 200) {
      dispatch(setPausesHistory(response.data.listPauses))
      return {
        status: "success",
        data: response.data,
      };
    }
    return {
      status: "error",
      type: "unknown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};
export const selectVehicles = (state) => state.vehicles.vehicles;
export const selectDriver = (state) => state.vehicles.driverInfo;
export const selectListPauses = (state) => state.vehicles.listPauses;
export const selectPausesHistory = (state) => state.vehicles.pausesHistory
export default vehiclesSlice.reducer;
