import { useState } from "react";
import styles from '../styles/Editor.module.css'
import Close from '../../../components/Close'
import { Button, Col, Form, Input, Row, message } from 'antd'

export const PasswordEditor = ({close, changePassword, vehicle}) => {
  const [sending, handleSending] = useState(false);
  const [password, handlePassword] = useState("");

  const handleNewPassword = async () => {
    if (password.length) {
      handleSending(true);
      await changePassword(password, vehicle);
      handleSending(false);
      close();
    } else {
      message.error("Ingresa una contraseña");
    }
  };

  const newPassword = async (e) => {
    handlePassword(e.target.value);
  };

  return (
    <div className={styles.editor}>
        <div className={styles.header}>
            <span className={styles.title}>
                Restablecer Contraseña
            </span>
            <Close action={close}/>
        </div>
        <Form>
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <span className={styles.label}>
                  CONTRASEÑA NUEVA
                </span>
              }
              name="new_password"
              className={styles.itemColumn}
              rules={[{ required: true, message: 'Ingresar contraseña nueva'}]}
            >
              <Input.Password size="large" className={styles.input} value={password} onChange={newPassword} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item className={styles.itemSubmit}>
              <Button
                loading={sending}
                onClick={handleNewPassword}
                size="large"
                type="primary"
                className={styles.submit}
              >
                CAMBIAR CONTRASEÑA
              </Button>
            </Form.Item>
          </Col>
        </Row>
        </Form>
    </div>
  )
}
